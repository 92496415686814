ul.tree,
ul.tree ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.tree ul {
  margin-left: 10px;
}
ul.tree li {
  margin: 0;
  padding: 0 7px;
  line-height: 50px;
  color: rgba(69, 23, 125, 1);
  font-size: 1rem;
  border-left: 1px solid rgb(100, 100, 100);
}
ul.tree li:last-child {
  border-left: none;
}
ul.tree li:before {
  position: relative;
  top: -0.3em;
  height: 2em;
  width: 12px;
  color: white;
  border-bottom: 1px solid rgb(100, 100, 100);
  content: "";
  display: inline-block;
  left: -7px;
}
ul.tree li:last-child:before {
  border-left: 1px solid rgb(100, 100, 100);
}
