.resources-main {
  width: auto;
}

.resources-heading {
  margin-top: 3%;
  margin-left: 1%;
  color: rgba(140, 2, 93, 1);
  font-weight: bold;
  font-size: 3rem;
}

.resources-sharing-div {
  display: flex;
  flex-wrap: wrap-reverse;
  /* border: 2px solid red; */
  justify-content: space-around;
  align-items: center;
}

.resources-right {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 38vw;
  min-width: 300px;
}

.but-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.but-div > img {
  margin-left: 10px;
}

.buttons {
  display: flex;
  margin-top: 8%;
  justify-content: space-around;
  column-gap: 2.5rem;
}

.main-tree {
  color: rgba(69, 23, 125, 1);
  font-size: 2rem;
  text-decoration: underline;
}
.down-desktop {
  width: 25vw;
  min-width: 200px;
}

.down {
  margin-top: 5%;
  margin-bottom: 5%;
}

.ul {
  line-height: 1.8rem;
  margin-left: 6%;
  margin-top: 5%;
  list-style-type: square;
}
.ol {
  list-style-type: lower-roman;
  color: red;
}
.oli {
  color: rgba(69, 23, 125, 1);
}
.li {
  color: rgba(69, 23, 125, 1);
  font-size: 1.2rem;
}
@media (max-width: 768px) {

  .li{
    font-size: 1rem;
  }

}
.p {
  color: rgba(69, 23, 125, 1);
}

.research-image-div {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.research-img {
  width: 60%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.resources-left {
  padding-left: 20px;
}
